/**
 *
 * @author johan.magnusson@svenskaspel.se (Johan Magnusson)
 */

export const DIRECTION_UP = 'up';
export const DIRECTION_DOWN = 'down';
export const DIRECTION_BOTH = 'both';
export const DIRECTION_NONE = 'none';
export const STATE_EXPANDED = 'expanded';
export const STATE_HALF_EXPANDED = 'half-expanded';
export const STATE_COLLAPSED = 'collapsed';

export const STATE_CLOSED = 'closed';

export const BEHAVIOR_STANDARD = 'standard';
export const BEHAVIOR_MODAL = 'modal';
export const BEHAVIOR_HYBRID = 'hybrid';
export default {
  DIRECTION_UP,
  DIRECTION_DOWN,
  DIRECTION_BOTH,
  DIRECTION_NONE,
  STATE_EXPANDED,
  STATE_HALF_EXPANDED,
  STATE_COLLAPSED,
  BEHAVIOR_MODAL,
  BEHAVIOR_STANDARD,
  BEHAVIOR_HYBRID
};
